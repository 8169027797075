:root {
    /*Margins & Paddings*/
    --padding: 1rem; 
    --margin: 1rem; 
    --vertical-padding: 1rem; /* must NOT be a percentage value */
    --vertical-margin: 1rem; /* must NOT be a percentage value */
    /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 1300px;
    /*General*/
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
    --map-marker-color: 000;
    /*Colors*/
    --text-color: #303030;
    --body-bg: #fff;
    --main-color: #eee;
    --accent-color: #39959a;
    --effect-color: #93cfd2;
    --header-text-color: --text-color;
    --header-bg-color: #fff;
    --footer-bg-color: #2c3e37;
    --footer-text-color: #000;
    --menu-text-color: #a5a299;
    --menu-text-hover-color: var(--text-color);  
    /*Fonts*/
    --base-font-family: "Lato", "Helvetica", "Arial", sans-serif;
    --alternative-font-family: "Old Standard TT", "Helvetica", "Arial", sans-serif;
}


@import "/fontmodules/Lato.css";
@import "/fonts/Old Standard TT/all.css";

@import "/css/pikaday.css";
@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.2.css";
@import "/cssmodules/magnific-popup.css";
/*@import "/cssmodules/animate.css";*/




tr td:nth-child(1) {width: 10% !important;}
tr td:nth-child(2) {width: 40% !important;}
tr td:nth-child(3) {width: 10% !important;}
tr td:nth-child(4) {width: 10% !important;}
tr td:nth-child(5) {width: 30% !important;}
a.readmore2 {
    padding: .3em 1em;
    background: #e8e8e8;
    font-size: 1.1rem;
    color: #303030;
}
.scrollup {
    width: 59px;
    height: 59px;
    position: fixed;
    bottom: 50px;
    right: 30px;
    text-indent: -9999px;
    background: url(/files/2017-07/up.png);
}
.epostlankar{color:#bcbcbc;}

.twelve.margin.flex.relateradedrycker img {
    height: 150px;
    width: auto;
    align-self: center;
    margin-left: 25%;
}
.pdflink a{color:#a50000;}
.prodlink a:hover{color:#00f;}
/* ------------ Tabell --------------*/
table {
    width: 100% !important;
}
td {
    border: none;
    
}
tr {
    border-bottom: 1px solid #dcdcdc !important;
}

.prislista table tr.bgprod{
    background: #eee;;

}

.wineitem {
    background: #fff;
}
.pdflink img{
    display:inline-block;
}

.systembol .button {
    border-radius: 0;
    color: #fff;
    padding: 0.5em 1em 0.5em 0.5em;
    background: #2c3e37;
    color: #fff;
    margin: 0px;
    min-width: 293px;
}


.back .button {
    border-radius: 0;
    color: #fff;
    padding: 0.5em 1em 0.5em 0.5em;
    background: #2c3e37;
    color: #fff;
    margin: 0px;
}
.back .button:hover {
    background: #24312c
}


.back .button svg {
    fill: #fff;
}



.instagram {
    background: #eee;
}



.nyhetsbrev input {
    min-width: 100%;
}

.nyhetsbrev .button {
    background: #e0e0e0;
    margin: 0;
    text-transform: uppercase;
    color: #303030;
    border: 0px solid transparent; 
    margin-bottom: 20px;
}

.nyhetsbrev .button:hover {
    background: #d6d6d6;
    margin: 0px;
    color: #303030;    
    margin-bottom: 20px;

}




.index-8 .dropdown .sub-menu ul.sub-menu-items{display:block;}

ul.sub-menu-items {
    padding: 0px;
    display: none;
}
.dropdown > a {
    background: #fff;
    margin-bottom: 0;
    padding: 7px;
    color: #fff;
    color: #303030;
    display: block;
    border-bottom: 1px solid #e4e3e3;
}
.dropdown > a:hover {
    color: #303030;
}
.sub-menu ul {
    list-style-type: block;
}

.sidemenu {
    list-style-type: block;
        text-transform: uppercase;
    padding: 0px;

}
.sidemenu ul li {
    background: #f7f7f7;
    padding: 5px;
    border-bottom: 1px solid #e4e4e4;
}

.sidemenu ul li a {
    display: block;
}

.sidemenu  ul {
    list-style-type: none;
}
.sidemenu  ul li {
    text-transform: uppercase;
    padding: 7px 0px;
    padding-left: 10px;
    background: #f7f7f7;
    border-bottom: 1px solid #e4e4e4;
}
.sidemenu  ul li a {
}

.sidemenu  ul li:hover {
    background: #e4e4e4;
}
.sidemenu  ul li.active {
    background: #e4e4e4;
}
.flex.facebook {
    background: #eeeeee;
}

.index-8two {
    display: none;
}

.homepage h2 {
    text-align: center;
    font-size: 2em;
}

/*Facebook album*/
.album-bild {
    position:relative;
}
.album-bild img {
    display:block;
}
.info { 
    opacity:0;  
    position:absolute; 
    width:100%; 
    height:100%; 
    top:0; 
    left:0; 
    right:0; 
    bottom:0; 
    z-index:1; 
    padding: 10px; 
    font-size:0.9em; 
    line-height:1.4em; 
    text-align:right; 
    color:#e4e4e4;  
    background: linear-gradient(to bottom,rgba(50, 84, 71, 0.52) 0,rgba(63, 107, 90, 0.65) 100%)
}
.info img { 
    display: inline-block;
    height:17px; 
    width:auto; 
    margin:0 0 0 5px; 
    vertical-align:top !important; 
    fill:#e4e4e4;
}
.album-bild:hover .info {
    opacity:1; 
}

.representant a {
    display: block;
}



.four-special {
    flex-basis: calc(33.33333% - (40px + 1rem*2));
}


.systembolaget {
    display: block;
    max-width: 125px;
    max-height:20px;
    text-transform: uppercase;
    text-align:center;
    border-radius: 0px;
    background: #2c3e37;
    padding: 1em;
    color:#fff !important;
    margin: 1em;
    font-size: 0.9em
}
.systembolaget:hover{
    background:#374d44;
    color:#fff;
}
.newsletter{
    display: block;
    text-transform: uppercase;
    text-align: center;
    border-radius: 0;
    background: #2c3e37;
    padding: 1em;
    color: #fff!important;
    margin: 1em;
    font-size: .9em;
}
.newsletter:hover{
    background:#374d44;
    color:#fff;
}
.producentknapp .button {
    display: block;
    max-width: 125px;
    max-height:20px;
    text-transform: uppercase;
    border-radius: 0px;
    background: #e3e3e3;
    padding: 1em;
    margin: 1em;
    font-size: 0.9em
}
.pdflink{
    color:#4b9560;
}
.producentknapp .button:hover {
    background:#dadada;
    
}

.producentknapp h2 {
    margin-top: 0px;
}
.producenttitel {
    margin: 0em;
    margin-bottom: 0px;
}

.subtopmenu ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}
.subtopmenu ul li {
    text-transform: uppercase;
    padding: 7px 0px;
    padding-left: 10px;
    background: #f7f7f7;
    border-bottom: 1px solid #e4e4e4;
}
.subtopmenu ul li a {
    padding: 10px 120px 10px 10px;
}

.subtopmenu ul li:hover {
    background: #e4e4e4;
}
.subtopmenu ul li.active {
    background: #e4e4e4;
}






.wineitem {
    opacity: 1;
}
.wineitem:hover {
    opacity: 0.8
}

.wineitem {
    box-shadow: 0 0 1px 0 #949292;
}


.sektion {
    padding: 4vh 0;
}
.sektion h2 {
    margin-top: 0px;
}


.sektion.producent {
    background: #eee;
}

/* ----------------Banner------------  */
.bannerstilla  {
    min-height: 50vh;
    text-align: center;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    color: #000;
}

.bannerstilla a {
    color: #000;
}
.banner {
    min-height: 50vh;
    background-attachment: fixed;
    text-align: center;
    background-position: center top;
    background-size: cover;
    color: #fff;
}
.banner h2,.banner a {
    color: #fff;
}
.banner h2, .bannerstilla h2 {
    font-size: 2em;
}
.banner .button {
    background: #939d4c;
    text-transform: uppercase;

}
.bannerstilla .button {
    background: #f2d184;
    text-transform: uppercase;
    border-radius: 0px;
}
.bannerstilla .button:hover {
    background: #eee;
    border-radius: 0px;
}
.banner .button:hover {
    background: #252525;
    color: #fff;

}

/* ----------------producent------------  */

.producentbild {
    min-height: 30vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.producentitem h3, .dryckitem h3 {
    text-align: center;
}
.readmore {
    text-align: center;
    opacity: 0;
    transition: opacity 1s ease;
}
.producentitem:hover .readmore {
    opacity: 1;
}
.dryckitem h3 {
    font-family: lato;
    font-weight: 300;
} 
/* ----------------drycker------------  */
.dryck {
    background: #fff;
}
.dryckbild {
    min-height: 30vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}



.contactform h2 {
    font-size: 2em;
    text-align: center;
}

.producenterstart h2, .dryckerstart h2 {
    text-align: center;
    font-size: 2em;
}

/* faq */

.productinfo .faqlist .catname div.toggle{
    color:#fff;
}
.productinfo .catname .toggle:after, .post-item .togglechild:after{
    top:0;

}
.om-oss .inner .toggle h3 {
    background: #d3d3d3;
    color: #fff;
    max-width: 100px;
    text-align: center;
    border-radius: 5px;
}
.accordion.flex {
    align-items:flex-start;
}

.faqlist .inner {
    overflow: hidden;
    display: none;
    background: #f8f8f8;
    box-shadow: 1px 1px 1px 1px #ccc;
    padding:1em;
    
}
.faqlist .inner table tr td{
    font-size: 0.75rem;
}

.faqlist a {
    text-decoration:none;
}

.faqlist .catname div.toggle {
    cursor: pointer;
    position:relative;
    width: 100%;
    display: block;
    color: #222;
    padding: .5em;
    font-weight: 400;
    transition: font-weight .3s ease;
    background:#de0029;
    margin:.3em 0;
    border-bottom: 1px solid #f2f2f2;
}
.toggle h3{
    color:#fff;
    margin:0;
    font-weight: 400;
}

.faqlist .catname:first-child div.toggle {
    margin-top: 0.5rem;
    border-top: 1px solid #f2f2f2;
}

.faqlist .catname .toggle:hover {
    font-weight: 300;
    opacity:.7;
}

[data-tabname] {
    display: none;
}

[data-tabname].active {
    display: flex;
}

.posts {
    margin-top:0.5em;
}


.faqlist .post-item  a.toggle {
    background:#f1f1f1;
    border-bottom:#f2f2f2 solid 1px;
    color:black;
}

.catname .toggle:after, .post-item .togglechild:after {
    content: "✚";
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 2rem;
    color: #fff;
}

.catname .active.toggle:after, .post-item .active.togglechild:after {
    content:"✖";
    position:absolute;
    right:10px;
}






/*------------------------------------------------------------------------------------------------ 
GENERAL ----------------------------------------------------------------------------------------*/

body {
    font-family: var(--base-font-family);
    line-height: 1.5;
    font-weight: 400;
    color: var(--text-color);
    background: var(--body-bg);
/*-  background: linear-gradient(rgba(255, 255, 255, 0.7),rgba(255, 255, 255, 0.7)), url('/thumbs/1920x1080r/theme/intendit/graphics/bgtm.png') !important; */
}

body.taxonomylink {
/*-     background: linear-gradient(rgba(255, 255, 255, 0.1),rgba(255, 255, 255, 0.1)), url('/thumbs/1920x1080r/theme/intendit/graphics/bgtm.png'); */
}

main {
    border-top: 100px solid transparent;
   /* border-top: 157px solid transparent;*/
}

p {
    margin: 3px 0 5px;
}
a, a:hover {
    text-decoration: none;
    color: #303030;
}

.content-wrapper a {
    text-decoration: underline;
    color: #303030;
}
img {
    max-width: 100%;
    height: auto;
    flex: none;
    display: block;
}

.imagetop {
    min-height: 15vh;
    background: no-repeat center center;
    background-size: cover;
}

figure {
    margin: 0px;
}
.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

.button {
    margin: 1rem 0px;
    background: #4b9560;
    border: none;
    transition: background 1s;
}
a.button{
    color:#fff;
    text-decoration:none;
}

.button:hover {
    background: #666;
}


    


/* ------------------------------- FONTS + HEADINGS ---------------------------- */
h1, h2, h3, h4, h5, h6 {
    font-family: var(--alternative-font-family);
    margin: 0.5em 0 0 0; 
    font-weight: 700;
}
.slick-title {
    font-size: 1.6rem;
    font-family: var(--alternative-font-family);
    font-weight: 600;
    text-align: center;
}

/* ------------- WRAPPERS ------------- */
.container.sml {
    max-width: 900px;
}
.container.med {
    max-width: 1300px;
}
.container.lrg {
    max-width: 1500px;
}
.container {
    width: 100%; /*fixar issue med bredd i vissa flexelement i IE*/
}
.wrapper {
    min-height: calc(100vh - 123px);
    position: relative;
    /*margin-top: 80px; För att flytta ner innehåll vid position:fixed på header*/
}

/*------------------------------------------------------------------------------------------------ 
STARTSIDA ----------------------------------------------------------------------------------------*/

/* ---------------SLIDER----------- */
.slick {
    margin-bottom: 0;

}
.slick-track, .slick-substitute {
    display: flex;
    flex-direction: row;

} 
.slick-initialized .slick-slide, .slick-substitute > div {
    height: 100%;
    min-height: 69vh;
    width:100%;
    display: flex;
    background-size: cover;
    background-position: center center;
}

/*------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- 
CONTENT -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

/* ----------------STARTTEXT------------  */

.starttext {
    margin-bottom: 3vh;
}

.starttext h1 {
    text-align: center;
    font-size: 2.5em;
    letter-spacing: -1px;
}

.startcontent .instagram {
    margin-top: -2em;
}

.introcitat {
    font-size: 1.2em;
    font-weight: 600;
    text-align: center;
    color: #2c3e37;
    font-style: italic;
}


/* ------------------STARTBOXARNA----------- */
.startbox {
    height: 39vh;
    background-size: cover;
    background-position: center center;
}
a.boxwrapper {
    background-color: rgba(0, 0, 0, 0);
    height: 100%;
    transition:background 0.5s ease-in 0s;
}
.startbox:hover a.boxwrapper {
    background: rgba(109, 106, 106, 0.3);  
    transition:background 0.5s ease-in 0s;
}
a.boxwrapper h2 {
    transition:color 0.5s ease-in 0s;
}
.startbox:hover a.boxwrapper h2 {
    color:#ffffff;
    transition:color 0.5s ease-in 0s;
}


/*------------------------------------------------------------------------------------------------ 
Nyheter & Nyhet--------------------------------------------------------------------------------*/
/* -----------------NYHETER---------------- */

.artikelbild {
    float: right;
    max-width: 40%;
    height: auto;
    margin: 0 0 15px 15px
}
.newsitem hr {
    color: #efefef;
}

/*------------------------------------------------------------------------------------------------ 
General content--------------------------------------------------------------------------------*/

/* ----------------PAGINAION------------  */

div.pagination {
    margin: 2rem auto 0;
    padding-bottom: 1vh;
    list-style: none;
    width:100%;
    text-align: center;
}

ul.pagination  li {
    display: inline-block;
    padding-right: 5px;
}

ul.pagination  li a {
    background: #000;
    color: white;
    text-decoration: none;
    padding: 4px 8px;
}

.pagination .active a, .pagination a:hover {
    background: #303030;
}


/* -------------- logo gallery ------------ */
.logotype-gallery {
    padding: 2em 0;
}
.logotype-image {
    min-height: 70px;
} 
.logotype-image div, .logotype-image a {
    text-align: center; 
    width: 100%; 
    flex:0 0 auto; 
    display: block;
} 
.logotype-image img {
    height: auto;
} 
.standing  {
    width:40%; 
} 
.squarish  {
    width:40%; 
} 
.landscape  {
    width:55%; 
}
.longlandscape {
    width:70%; 
}
.extralonglandscape {
    width:100%; 
}

/* -------------- mfp-ajax ------------ */

.mfp-ajax-holder .wrapper{
    min-height: auto;
}

.mainAjaxContent :first-child{
    margin-top: 0;
}

.mfp-ajax-holder{
    background: #FFF;
    padding: 20px 30px;
    text-align: left;
    max-width: 650px;
    margin: 40px auto;
    position: relative;
    height: calc(100% - 80px);
    overflow-y: auto;
}

.mfp-close {
    margin: 0;
}

/*------------------------------------------------------------------------------------------------ 
HEADER -----------------------------------------------------------------------------------------*/

/* ------------NAV + HEADER--------------- */
header {
    z-index: 10;
    width: 100%;
    position: fixed; /*om fixed el absolute, glöm ej margin-top på main*/
    top: 0;
    padding: 10px;
    background-color: #fff;
    color: #303030;
    transition: background-color 0.3s, color 0.3s;
}

.scrolled header {
    background-color: #fff; 
    color: #303030;

}
.scrolled header nav ul.menu li a {
    text-shadow: none;

}

li.logo {
 display: flex !important;
    align-items: center;
    justify-content: center;
}
.logo {
    padding: 10px;
    display:flex;
}
.logo a, .logo img {
    width: auto; 
    display: block;
    max-width:150px;
}

header nav {}
header nav ul.menu {}
header nav ul.menu li {
}
header nav ul.menu li a {
    font-size: 0.9em;
    letter-spacing: 1px;
    font-weight: 300;
    text-transform: uppercase;
    white-space: nowrap;
    border-bottom: 1px solid transparent;

}
li.logo a:hover {
    border-bottom: 1px solid transparent !important;

}
.menu.menu li a:hover, .menu.menu li.activeparent a {

    border-bottom: 1px solid #303030;

}
header nav ul.menu.show, header nav ul.menu.menu, header nav ul.menu li a,  header nav ul.menu .dropdown ul,  ul.sub-menu-items {
    background: #fff;
    color: #303030;
    text-shadow: none;

}



/*
nav ul.menu li.dropdown > a:after { content: "<"; display: inline-block; margin: 0 0 0 5px; }
*/
.logostart {
    display: none;
    padding-bottom: 2vh;
}

/*----------------------------MOBILMENY----------------------------*/
@media print{

header nav, footer, a, .button, .imagetop{
display: none;
}
    header.logostart{
        display:block;
    }
    
    
}


@media (max-width: var(--breakpoint-menu)) 
{
    header nav ul.menu.show, header nav ul.menu.menu, header nav ul.menu li a,  header nav ul.menu .dropdown ul,  ul.sub-menu-items {
        background: #fff;
        color: #303030;
        text-shadow: none;

    }
    nav .logo img  {
        display: none;
    }
    li.logo  {
        display: none;
    }
    .slick-initialized .slick-slide, .slick-substitute > div {
        background-position: center center;
    }
    .logostart {
        display: inline-block;
    }
    header {
        text-align: center;
    }
    header nav ul.menu li {
        margin: 0px;
    }
    header nav ul.menu li a {
        margin: 0px;

    }
    .menu.menu li a:hover, .menu.menu li.active a {

        border-bottom: 1px solid #fff;

    }
    .menu.show {
        max-height: calc(100vh - 200px);
    }


}


/*------------------------------------------------------------------------------------------------ 
FOOTER & KONTAKT--------------------------------------------------------------------------------*/
/* -----------------FOOTER---------------- */
footer img {
    max-height: 70px;
    display: inline;
    transform: translateY(45%);
}

.wrapperfooter {
    margin: 0 10px;
}

footer {
    /*background-image: url('/theme/intendit/graphics/footerbg.jpg');*/
    text-align: center;
    font-weight: 300;
    padding-bottom: 20px;
}
footer, footer a, footer a:hover { 
    color: var(--footer-text-color); 
}
footer svg {
    margin-right: 10px;
    fill: var(--footer-text-color);
}
footer h4 {
    margin-top:0;
}

.sociala svg {
    fill: #303030;
    
}

.sociala a {
    background: #e2e2e2;
    border-radius: 50%;
    padding: 10px;
    transition: background 0.5s ease;
    
}

.sociala a:hover {
    background: #d6d6d6;
    
}
.sociala h2 {
    padding-bottom: 12px;
    
}

/* -----------------Intendit-footer---------------- */
.created-by {
    background-image: url('/theme/intendit/graphics/footerbg.jpg');
    background-position: center;
    text-align: left;
    padding: 5px; 
}
p.intenditfooter, p.intenditfooter a, p.intenditfooter a:hover  {
    color: #22352e;
    line-height:1.1;
}
p.intenditfooter {
    margin:0;
}
/* -----------------KONTAKT---------------- */


/* -----------------KONTAKTFORMULÄR---------------- */

.boltform form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.boltforms-row {
    flex-basis: calc(100% - 20px);
    padding-right: 10px;
    padding-left: 10px;
    box-sizing: content-box;
}

.boltforms-six-row {
    flex-basis: calc(50% - 20px);
}

.boltforms-four-row {
    flex-basis: calc((100% / 3) - 20px);
}

.boltforms-three-row {
    flex-basis: calc(25% - 20px);
}

.boltform textarea, .boltform input {
    width: 100%;
}

@media (max-width: var(--breakpoint-s))
{
    .boltforms-row {
        flex-basis: auto;
    }

    .boltform form {
        flex-direction: column;
    }
}

.formwrapper {
    background-color: #f4f4f4; 
    padding: 20px 0px;
}

.boltforms-row button {
    margin: 0 auto;
    display: block;
    background: #e0e0e0;
    border: none;
    border-radius: 0px;
    text-transform: uppercase;
    width: 100%;
    color: #303030;
    transition: background 0.5s ease;
}

.boltforms-row button:hover {
    background: #d6d6d6;
    color: #303030;

}
/* --------------- Google maps --------------------*/
/* Give map a height and it's images no max-width */
.map-canvas {
    height: 35vh;
}

.map-canvas img {
    max-width: none;
}

.fa-map-marker{
    background: url('data:image/svg+xml;utf8,%3Csvg%20fill%3D%22%23var(--map-marker-color)%22%20width%3D%221792%22%20height%3D%221792%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1152%20640q0-106-75-181t-181-75-181%2075-75%20181%2075%20181%20181%2075%20181-75%2075-181zm256%200q0%20109-33%20179l-364%20774q-16%2033-47.5%2052t-67.5%2019-67.5-19-46.5-52l-365-774q-33-70-33-179%200-212%20150-362t362-150%20362%20150%20150%20362z%22%2F%3E%3C%2Fsvg%3E');
    background-size: cover;
    padding: 10px;
    background-position: center;
}

/*------------------------------------------------------------------------------------------------ 
MEDIA QUERIES ----------------------------------------------------------------------------------*/
/*----------------------------Allt under mobilmeny----------------------------*/
@media (max-width: var(--breakpoint-menu)) 
{
    .logo img {
        display: inline-block;
    }

}

/*----------------------------Allt under 1500px----------------------------*/
@media (max-width:1500px)
{
    .container.lrg {
        width: 96%; 
    } 

    .formwrapper {
        margin-top: 1vh;
    }
}
/*----------------------------Allt under 1300px----------------------------*/

@media (max-width:1300px)
{
    .container.med {
        width: 96%; 
    } 
    main {
        /*border-top: 219px solid transparent;*/
    }
    .slick-initialized .slick-slide, .slick-substitute > div {
        min-height: 57vh;
        
    }
}

/*----------------------------Allt under 1100px----------------------------*/
@media (max-width: var(--breakpoint-m))
{

    .container {
        width: 96%; 
    } 
    .producentknapp .button {
        min-width: 100%;
    }
    footer img {
        max-height: 50px;
    }
    


}

/*----------------------------Allt under 800px----------------------------*/
@media (max-width: 950px)
{
    footer img {
        display: none;
    }
    footer {
        padding-top: 50px;
    }
}

/*----------------------------Allt under 800px----------------------------*/
@media (max-width: var(--breakpoint-s))
{
    .container.sml {
        width: 96%; 
    } 

    footer .s-twelve {
        text-align: center; 
    }
    footer .s-twelve a {
        display:block; 
        text-align:center; 
        margin: 0 auto; 
    } 
    .banner {
        background-attachment: initial;
    }
    .producentbild {
        min-height: 40vw;

    }
     .logostart img {
        max-height: 100px;
    }
      main {
        border-top: 190px solid transparent;
    }

}
/*---------------------------MEDIA XS - upp till 550-----------------------------*/
@media (max-width: var(--breakpoint-xs))
{ 
    .logostart img {
        max-height: 70px;
    }
    #menu-icon {
        padding: 2px;
    }
    .producentbild {
        min-height: 50vw;

    }
    main {
        border-top: 139px solid transparent;
    }
    .starttext h1 {
        font-size: 1.5em;
    }
    .introcitat {
        font-size: 1em;
    }
    footer {
        padding-top: 15px;
        padding-bottom: 0px;
    }
    td {
        border: none;
        display: block;
        width: 100% !important;
        padding: 5px 1em !important;
    }
    tr td:nth-child(1) {width: 100% !important;}
    tr td:nth-child(2) {width: 100% !important;}
    tr td:nth-child(3) {width: 100% !important;}
    tr td:nth-child(4) {width: 100% !important;}
   

}





.menu {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    flex: 0 0 300px;
    transition: flex-basis 0.5s ease;
    display: block;
}

body:not([data-iehacks]).scrolled .logo {
    flex-basis: 10%;
}

/*
 * IE specific hacks, data attrib set in JS if browser matches IE10/11 UA
 * Can be removed when IE support drops 
 */

[data-iehacks] .logo,
[data-iehacks] .logo img{
    flex: 0 0 auto;
    width: 300px;
    transition: width 0.5s ease;
}
[data-iehacks].scrolled .logo,
[data-iehacks].scrolled .logo img{
    width: 200px;
}

.mainmenu, .logostart{
    margin: 0 auto;
}



/*----Popup----*/
.homepage .mfp-bg {
    opacity:0.65; 
}
.popup-inner { 
    background:#fff; 
    padding: 2px 20px 20px; 
    margin:40px auto; 
    position:relative; 
    width:100%; 
    max-width: 550px; 
    text-align: center;
}
.popup-inner .button {
    width: 100% !important; 
    margin:0; 
    border:none; 
    background: #538e77 !important; 
    color: #fff;
}
/*---------------------Support-----------*/
/*table*/
.table-wrapper {display:table; width:100%; } /*löser display i vissa IE verioner*/
.table-wrapper .fa-icon {height: 25px; width:100px; display:block; margin: 0 auto; background:#d0d0d0; fill:#fff; border-radius:40px;  }
.table-wrapper h3 {width:100%; margin-top: 10px; }
.table-wrapper table tr:nth-child(even){
    background:#ccc;
}
table {font-size:95%; width:100%;}
table tr {border-bottom: 1px solid #e4e4e4;}
table tr td {padding:5px 20px 5px 0; vertical-align: top; border:none; }

@media (max-width:500px){
.prislista table tr td:nth-child(even){
    background:#ccc;
}
    

    
}
